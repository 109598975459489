import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
    user: null,
    ghostSettings: {
      navigation: []
    },
    appHeader: {
      title: 'Pearl DGS Calculator®',
      html: ''
    },
    ginputs: {
      patientID: null,
      side: true,
      keratometricIndex: null,
      AL: null,
      K1: null,
      K2: null,
      ACD: null,
      LT: null,
      CCT: null,
      nvit: null,
      biometer: null,
      IOLModel: null,
      aconst: null,
      is60MA: false,
      target: null,

      isNonPhysioCornea: false,
      isICL: false,
      isRK: false,
      isCornealLaser: false,
      cornealLaserType: null,
      laserAmount: null,
      preLvcArc: null,
      PRC_topo: null,
      TK: null,

      isSecond: false,
      AL2: null,
      K12: null,
      K22: null,
      ACD2: null,
      LT2: null,
      CCT2: null,
      power2: null,
      SE2: null,
      aconst2: null,
      isCornealLaser2: false,
      cornealLaserType2: null,
      laserAmount2: null,
      isICL2: false,
      preLvcArc2: null,
      PRC_topo2: null,
      TK2: null
    },
    results: {
      target: null,
      iolp: null,
      p1: null,
      p05: null,
      p0: null,
      pm05: null,
      pm1: null
    },
    ginputs2: {
      patientID: null,
      side: true,
      keratometricIndex: null,
      AL: null,
      K1: null,
      K2: null,
      ACD: null,
      LT: null,
      CCT: null,
      nvit: null,
      biometer: null,
      IOLModel: null,
      aconst: null,
      is60MA: false,
      target: null,

      isNonPhysioCornea: false,
      isICL: false,
      isRK: false,
      isCornealLaser: false,
      cornealLaserType: null,
      laserAmount: null,
      preLvcArc: null,
      PRC_topo: null,
      TK: null,

      isSecond: false,
      AL2: null,
      K12: null,
      K22: null,
      ACD2: null,
      LT2: null,
      CCT2: null,
      power2: null,
      SE2: null,
      aconst2: null,
      isCornealLaser2: false,
      cornealLaserType2: null,
      laserAmount2: null,
      isICL2: false,
      preLvcArc2: null,
      PRC_topo2: null,
      TK2: null
    },
    results2: {
      target: null,
      iolp: null,
      p1: null,
      p05: null,
      p0: null,
      pm05: null,
      pm1: null
    }
  },
  mutations: {
    setAuthUser (state, user) {
      state.user = user
    },
    updateInputs (state, newInputs) {
      Object.keys(newInputs).forEach(key => !(key in state.ginputs) && delete newInputs[key])
      state.ginputs = { ...state.ginputs, ...newInputs }
    },
    updateInputs2 (state, newInputs2) {
      Object.keys(newInputs2).forEach(key => !(key in state.ginputs2) && delete newInputs2[key])
      state.ginputs2 = { ...state.ginputs2, ...newInputs2 }
    },
    setGhostSettings (state, ghostSettings) {
      // Remove link with https aka "internal app link" ()
      ghostSettings.navigation = ghostSettings.navigation.filter(item => !item.url.startsWith('https'))
      state.ghostSettings = ghostSettings
    },
    setResults (state, newResults) {
      state.results = newResults
    },
    setResults2 (state, newResults2) {
      state.results2 = newResults2
    },
    setAppHeader (state, page) {
      // page is content of app-header page from ghost API
      state.appHeader = page
    }
  },
  getters: {
    isLoggedIn (state) {
      return localStorage.getItem('jwt-token') !== null
    }
  },
  actions: {
    updateInputs ({ commit }, newInputs) {
      commit('updateInputs', newInputs)
    },
    updateInputs2 ({ commit }, newInputs2) {
      commit('updateInputs2', newInputs2)
    },
    setResults ({ commit }, newResults) {
      commit('setResults', newResults)
    },
    setResults2 ({ commit }, newResults2) {
      commit('setResults2', newResults2)
    },
    setAuthUser ({ commit }, user) {
      commit('setAuthUser', user)
    },
    setGhostSettings ({ commit }, ghostSettings) {
      commit('setGhostSettings', ghostSettings)
    },
    setAppHeader ({ commit }, page) {
      commit('setAppHeader', page)
    }
  }
})

export default store
