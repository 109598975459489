<template lang="html">
  <div>
    <header id="header" class="noprint">
      <!-- <a :href="ghostSettings.url" class="noprint logo">{{ ghostSettings.title }}</a> -->
    </header>
    <bottom-nav></bottom-nav>
    <transition :name="transitionName" mode="out-in">
      <router-view />
    </transition>
    <div id="copyright" class="noprint">
      <ul>
        <li>© IOL Solver</li>
        <li>
          Design
          <a href="https://blog.stuffaboutdatascience.com">Julien Almarcha</a>
        </li>
        <li>
          <a href="https://la-niche-a-jowiez.net/cv_dev_olivier/">Olivier Niechajowiez</a>
        </li>
      </ul>
    </div>
    <template v-if="ghostSettings.cover_image">
      <div
        class="bg"
        :style="{ backgroundImage: 'url(' + ghostSettings.cover_image + ')' }"
      ></div>
    </template>
    <template v-else>
      <div class="bg"></div>
    </template>
  </div>
</template>

<script>
import { onLogout } from './apollo'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    BottomNav: () => import('@/components/BottomNav.vue')
  },
  methods: {
    logout () {
      const client = this.$apollo.getClient()
      onLogout(client)
      this.setAuthUser(null)
      this.$router.push('/')
    },
    ...mapActions(['setAuthUser'])
  },
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    transitionName () {
      return 'fade'
    },
    ...mapState(['ghostSettings', 'appHeader'])
  },
  data: () => {
    return {
      resetstyle: { color: '#838383' },
      IOLModelItems: [
        { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        { type: 'Other_Men', text: 'Other IOL Model' },
        { type: 'Panoptix', text: 'Panoptix', aconst: 119.2 },
        { type: 'Vivinex', text: 'Vivinex', aconst: 119.2 },
        { type: 'SN60WF', text: 'SN60WF', aconst: 119.1 },
        { type: 'PCB', text: 'PCB00/ZCB00', aconst: 119.35 },
        { type: 'MX60', text: 'Envista MX60', aconst: 119.3 },
        { type: 'SA60AT', text: 'SA60AT', aconst: 118.85 },
        { type: 'Asphina', text: 'Asphina', aconst: 117.95 },
        { type: '60MA', text: 'MA/MN60MA' },
        { type: 'A1-UV', text: 'A1-UV', aconst: 119.2 },
        { type: 'AR40', text: 'AR40', aconst: 118.4 },
        { type: 'RayOne', text: 'RayOne', aconst: 118.6 }
      ],
      keratometricItems: [1.3315, 1.3375, 1.332, 1.336, 1.338],
      vitreousItems: [
        { type: 'Vitreous', value: 1.336 }
        // { type: 'Custom', value: '' }
      ],
      biometerItems: [
        { type: 'Argos', text: 'Argos (SOS-AL)' },
        { type: 'USimmersion', text: 'US (immersion)' },
        { type: 'USaplanation', text: 'US (applanation)' },
        { type: 'Other', text: 'Other' }
      ],
      cornealLaserTypeItems: [
        { type: 'None', text: 'None' },
        { type: 'Hyperopic', text: 'Hyperopic' },
        { type: 'Myopic', text: 'Myopic' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Material+Icons");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&display=swap");
@font-face {
  font-family: "FontAwesome";
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.eot")
      format("embedded-opentype"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2")
      format("woff2"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff")
      format("woff"),
    url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.ttf")
      format("truetype");
  font-style: normal;
  font-weight: normal;
}

html {
  font-size: 15pt;
}
// Custom classes
.container.ghost {
  padding: 2rem 1rem 2rem 1rem;
}
.headline {
  font-size: 2rem !important;
  font-weight: 300;
  margin: 16px 0;
  padding-bottom: 1rem !important;
}
.logo {
  user-select: none;
  text-align: center;
  box-sizing: inherit;
  margin: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  text-decoration: none;
  color: #fff;
  transition: border-color 0.2s ease-in-out, color 0.2s ease-in-out, opacity 0.5s ease, transform 0.5s ease, visibility 0.5s;
  pointer-events: auto;
  border-style: solid;
  border-radius: 13px;
  border-color: #fff;
  border-width: 5px !important;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 2.25rem;
  font-weight: 900;
  letter-spacing: 0.075em;
  line-height: 1;
  padding: 1rem 1.75rem;
  text-transform: uppercase;
  visibility: visible;
}
.switch-eye.v-input--selection-controls {
  margin-top: 0px;
  margin-bottom: 20px;
  padding-top: 0px;
}
.switch-eye .v-messages__message {
  margin-top: -60px;
  min-width: 100px;
}
.switch-eye-label-right {
  text-align: right;
}
.switch-eye-label-left {
  text-align: left;
}
.bg-blue {
  background-color: #9cccf0;
}
.bg-gray {
  background-color: #c4c4c4;
}
.gray a {
  color: #838383;
}
.gray {
  color: #838383;
}
.iol-v-sheet {
  padding: 10px;
  background-color: inherit !important;
}
.iol-right-v-sheet {
  background-color: inherit !important;
}
.iol-left-v-sheet {
  background-color: #c1def3 !important;
}
.iol-opt-v-sheet {
  padding: 10px;
  background-color: #a4bdcf !important;
  border-radius: 3px !important;
}
.iol-results.v-data-table {
  border: 1px solid #000 !important;
  border-radius: 3px !important;
  background-color: inherit !important;
  text-align: center !important;
}
.iol-results.v-data-table thead tr th {
  background-color: #c4c4c4 !important;
  color: #000 !important;
  font-size: 18px !important;
  font-style: bold !important;
  text-align: center !important;
}
.iol-results.v-data-table tr > td:first-child {
  border-right: 1px solid #000 !important;
}
.iol-results.v-data-table th:first-child {
  border-right: 1px solid #000 !important;
}
.iol-results.v-data-table tfoot tr td {
  background-color: #c4c4c4 !important;
  color: #000 !important;
  font-size: 18px !important;
  font-style: bold !important;
  text-align: center !important;
}
.iol-results.v-data-table tfoot tr > td:first-child {
  border-right: 0px solid #000 !important;
}

.iol-v-text-field.v-text-field.v-text-field .v-input__control .v-input__slot {
  background-color: #fff;
  min-height: 0px !important;
  padding: 0 8px !important;
  margin-bottom: 2px !important;
  display: flex !important;
  align-items: center !important;
}
.iol-v-text-field.v-text-field.v-text-field .v-input__control .v-input__slot label {
  margin-top: -12px !important;
}
.iol-v-text-field.v-text-field.v-text-field .v-input__control .v-input__slot label.v-label--active {
  margin: -3px 0 0 5px !important;
}
// .iol-v-text-field.v-text-field__details {
//   margin: 2px !important;
// }
.iol-v-select.v-select.v-text-field.v-text-field--enclosed .v-select.v-text-field__details,
.iol-v-select.v-select.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  background-color: #fff;
  min-height: 0px !important;
  max-height: 32px !important;
  padding: 0 8px !important;
  margin: 0 !important;
  display: flex !important;
  align-items: center !important;
}
.iol-v-select.v-select.v-text-field .v-input__control .v-input__slot label {
  margin-top: -5px !important;
}
.iol-v-select.v-select.v-text-field .v-input__control .v-input__slot label.v-label--active {
  margin: 5px 0 0 2px !important;
}

// Custom v-elements
.v-application {
  font-family: "Source Sans Pro", sans-serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.v-application--wrap {
  min-height: 50vh !important;
}
.v-main {
  position: initial;
  background-color: #e2ecf3;
  overflow: hidden;
  position: relative;
  max-width: 72rem;
  margin: 0;
  z-index: 2;
}
.row {
  margin: 0 !important;
}
.v-btn:not(.v-btn--round).v-size--x-large {
  width: 100%;
}

// Named elements
#header {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  cursor: default;
  color: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
  user-select: none;
  // height: 20rem;
  // padding-bottom: 8rem;
  padding-bottom: 4rem;
  position: relative;
  text-align: center;
  z-index: 2;
}
#copyright {
  position: relative;
  color: rgba(255, 255, 255, 0.25);
  cursor: default;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  font-size: 0.8rem;
  font-weight: 900;
  letter-spacing: 0.075em;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  margin: 2rem auto 2rem auto;
  width: calc(100% - 4rem);
  max-width: 72rem;
  z-index: 2;
}
#copyright ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}
#copyright ul li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}
#copyright ul li {
  border-left: solid 2px;
  display: inline-block;
  line-height: 1;
  margin-left: 1rem;
  padding-left: 1rem;
}
#copyright a {
  color: inherit;
  border-bottom-color: inherit;
}

// Theme
.theme--light.v-application {
  background: none !important;
  color: rgba(0, 0, 0, 0.87);
  width: calc(100% - 4rem);
  max-width: 72rem;
  margin: 0 auto;
}

@media screen and (max-width: 1680px) {
  html {
    font-size: 12pt;
  }
}

@media screen and (max-width: 1280px) {
  html {
    font-size: 11pt;
  }
  .container.ghost {
    padding: 2rem 2rem 4rem 2rem;
  }
  #header {
    padding-bottom: 4rem !important;
  }
  #copyright {
    margin: 2rem auto;
  }
}

@media screen and (max-width: 736px) {
  .container.ghost {
    // padding: 1rem 1rem 2rem 1rem;
    padding: 1rem 1rem 2rem 1rem;
  }
  #header {
    // height: 9rem;
    // padding-bottom: 1rem;
    padding-bottom: 2rem;
  }
  .v-main {
    width: 100%;
    padding: 1rem 1rem !important;
  }
  .theme--light.v-application {
    width: 100%;
  }
}

@media print {
  .noprint {
    display: none !important;
  }
  .v-sheet {
    font-size: 16px !important;
    line-height: 27px !important;
  }
  .headline {
    font-size: 1.5rem !important;
  }
}

// Effects
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-left-enter,
.slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(-100%, 0);
  transition: 0.3s;
}
.slide-left-leave-active,
.slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(100%, 0);
  transition: 0.3s;
}
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-30px);
  opacity: 0;
}

// Main background
.bg {
  color: #212931;
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  top: 0;
  left: 0;
  background-color: #212931;
  background: linear-gradient(
      352deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -352deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      306deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -306deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      290deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -290deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -330deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      330deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -172deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      172deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -120deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -110deg,
      rgba(27, 0, 255, 0) 85%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      210deg,
      rgba(27, 0, 255, 0) 85%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      220deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -210deg,
      rgba(27, 0, 255, 0) 85%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      -220deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      120deg,
      rgba(245, 33, 33, 0) 87%,
      rgba(255, 255, 255, 0.08) 0
    ),
    linear-gradient(
      110deg,
      rgba(27, 0, 255, 0) 85%,
      rgba(255, 255, 255, 0.08) 0
    ),
    radial-gradient(ellipse, #8e9e99 0, #64b5f6 100%);
  background-size: auto, auto, 100% auto;
  background-position: center, center, top center;
  background-repeat: repeat, no-repeat, no-repeat;
  background-attachment: scroll, scroll, scroll;
  z-index: -1;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  will-change: transform;
  position: fixed;
  width: 100vw;
  height: 100vh;
  transform: none;
}
// Columns
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  width: 100%;
  padding: 2px !important;
}
</style>
