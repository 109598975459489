import Vue from 'vue'

import GhostContentAPI from '@tryghost/content-api'
import store from '@/store'
import router from '@/router'

import { createProvider } from '@/apollo'

import VueAnalytics from 'vue-analytics'

import VueRaven from 'vue-raven'

import App from '@/App.vue'
import vuetify from './plugins/vuetify'
// import './registerServiceWorker'

Vue.config.productionTip = false

// Sentry for logging frontend errors
Vue.use(VueRaven, {
  dsn: process.env.VUE_APP_SENTRY_PUBLIC_DSN,
  disableReport: process.env.NODE_ENV === 'development'
})

if (process.env.NODE_ENV === 'production') {
  // / more info: https://github.com/MatteoGabriele/vue-analytics
  Vue.use(VueAnalytics, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router
  })
}

new Vue({
  vuetify,
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount('#app')

// Load app menu from ghost api
const api = new GhostContentAPI({
  url: process.env.VUE_APP_GHOST_URL,
  key: '86f3df0b2b499a72f8db242086',
  version: 'v3'
})

api.settings
  .browse()
  .then((settings) => {
    store.commit('setGhostSettings', settings)
  })
  .catch((err) => {
    console.error(err)
  })

api.pages
  .read({ slug: 'app-header' })
  .then((page) => {
    store.commit('setAppHeader', page)
  })
  .catch((err) => {
    console.error(err)
  })
