import Vue from 'vue'
import VueRouter from 'vue-router'

const routes = [
  { path: '/', component: () => import('@/components/Main.vue') },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/components/Main.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/regular',
    name: 'regular',
    component: () => import('@/components/RegularEyes.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/result_regular',
    name: 'result_regular',
    component: () => import('@/components/ResultRegular.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/complex',
    name: 'complex',
    component: () => import('@/components/ComplexEyes.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/result_complex',
    name: 'result_complex',
    component: () => import('@/components/ResultComplex.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/second',
    name: 'second',
    component: () => import('@/components/SecondEyes.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/result_second',
    name: 'result_second',
    component: () => import('@/components/ResultSecond.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/emailconfirmation/:token/:email',
    name: 'emailconfirmation',
    component: () => import('@/components/EmailConfirm.vue'),
    meta: {
      requiresAuth: false
    }
  }

]

Vue.use(VueRouter)
const router = new VueRouter({
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // },
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    // https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt-token') == null) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath, showPopUp: true }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
